<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Редактирование вопроса</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  exact
                  small
                  class="mr-2"
                  v-bind="attrs"
                  depressed
                  color="primary"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
                    params : {
                      testId,
                      topicId,
                      questionId
                    }
                  }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-list
                  </VIcon>
                </VBtn>
              </template>

              <span>Список ответов</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="red"
                  small
                  class="mr-4"
                  v-bind="attrs"
                  @click="removeQuestion(testId, questionId)"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удалить вопрос</span>
            </VTooltip>
          </VToolbar>
          <VCardText>
            <VForm ref="questionForm">
              <VRow>
                <VCol cols="10">
                  <VTextField
                    v-model="question.title"
                    label="Название вопроса"
                  />
                  <VTextField
                    v-model="question.level"
                    label="Уровень"
                  />
                  <TextEditor
                    v-model="question.originalDescription"
                    label="Описание"
                    class="mt-4"
                  />
                  <VExpansionPanels
                    v-if="question.description"
                    class="my-4"
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader>Описание (HTML)</VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <!-- eslint-disable vue/no-v-html -->
                        <TTTextarea
                          v-model="question.originalDescription"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>
                  <TextEditor
                    v-model="question.rightAnswerComment"
                    label="Комментарий к верному ответу"
                    class="mt-8"
                  />

                  <VExpansionPanels
                    v-if="question.rightAnswerComment"
                    class="my-4"
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader>Комментарий к верному ответу (HTML)</VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <!-- eslint-disable vue/no-v-html -->
                        <TTTextarea
                          v-model="question.rightAnswerComment"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>

                  <VBtn
                    depressed
                    color="primary"
                    @click="saveQuestion(testId, questionId)"
                  >
                    Сохранить
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TextEditor from '../../TextEditor.vue';

export default {
  name: 'QuestionEdit',
  components: {
    TextEditor,
  },
  inject: ['Names'],
  data() {
    return {
      question: {
        title: '',
        level: '',
        description: '',
        originalDescription: '',
        rightAnswerComment: '',
        topicId: null,
        questionImages: {
          imageUrl: '',
          imageUrlHtml: '',
        },
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
  },
  async created() {
    try {
      this.question = await this.getQuestion(this.testId, this.questionId);
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
  methods: {
    async getQuestion(testId, questionId) {
      const {
        title, level, description, rightAnswerComment,
      } = this;

      const payload = {
        testId,
        questionId,
        title,
        level,
        description,
        rightAnswerComment,
      };

      return this.$di.api.Talent.questionGet(payload);
    },
    async saveQuestion(testId, questionId) {
      const payload = {
        title: this.question.title,
        description: this.question.originalDescription,
        level: this.question.level,
        topicId: this.question.topicId,
        rightAnswerComment: this.question.rightAnswerComment,
      };

      try {
        await this.$di.api.Talent.questionUpdate({ testId, questionId, ...payload });
        this.$di.notify.snackSuccess('Вопрос успешно обновлён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async removeQuestion(testId, questionId) {
      try {
        await this.$di.api.Talent.questionDelete({ testId, questionId });
        this.$di.notify.snackSuccess('Вопрос успешно удалён');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTIONS,
          params: { testId: this.testId, topicId: this.topicId },
        });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
